<template>
  <v-container>
    <v-row justify="center" class="text-center mt-2">
      <v-col cols="6">
        <h4 class="text-h4 justify-center font-weight-regular">All Repositories</h4>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" lg="6">
        <v-card>
          <v-list v-if="items.length">
            <template v-for="(item, i) in items">
              <v-divider v-if="i" :key="i" />
              <v-list-item
                :key="item.title"
                :to="{ name: item.routeName, params: item.routeParams }"
              >
                <v-list-item-avatar>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon small>mdi-arrow-right</v-icon>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
          <div class="py-15 text-center grey--text" v-else>
            <v-icon size="36">mdi-alert</v-icon>
            <div>You do not have access to any items</div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as api from "@/constants/api";
import { repositoryItems } from "@/constants/repositoryItems";

export default {
  name: "Repository",
  metaInfo: { title: "Repositories" },
  data() {
    return {};
  },
  computed: {
    items() {
      return Object.keys(repositoryItems)
        .sort()
        .filter((el) => this.verifyPrivilege(api.REPO_ITEM_LIST.replace(":item:", el)))
        .map((el) => repositoryItems[el]);
    },
  },
};
</script>

<style scoped></style>
